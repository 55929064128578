$theme: ekonomi;
@use "design-system" as ds;

.pageContainer {
  padding-top: ds.spacing(base);
  margin: 0 auto ds.spacing() auto;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;

  @media (min-width: ds.$screen-size--small) {
    max-width: ds.$content-width--max;
  }
}
